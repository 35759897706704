import {HttpContext, HttpErrorResponse} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {
  ApiClient, authActionLogout, AuthActionTypes,
  ErrorHandler,
  errorHandlersHttpContextToken,
  ForlabsExceptionUUIDs,
  HttpClientOptions,
  isForlabsException,
  ToastService,
} from '@forlabs/api-bridge';
import {EntityActionFactory, EntityOp} from '@ngrx/data';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, of, switchMap, tap, throwError} from 'rxjs';
import {ForlabsEntityOp} from '../articles/articles.metadata';
import {Article} from '../articles/articles.models';
import {CourseMessage} from '../course-messages/course-messages.models';
import {Info} from '../infos/infos.models';
import {Organization} from '../organizations/organizations.models';
import {PatientStep} from '../patient-steps/patient-steps.models';
import {Contact, HealthPro, Patient} from '../users/users.models';
import {WorkflowButton} from '../workflow-buttons/workflow-buttons.models';
import {Workflow} from '../workflows/workflows.models';
import {FailedSecureAction, metaActionInit, metaActionSecureAction} from './actions';


export const sensitiveActionIncorrectPasswordHandler: ErrorHandler = {
  matches: (r: HttpErrorResponse) => isForlabsException(r) && r.error.uuid === ForlabsExceptionUUIDs.SensitiveActionIncorrectPassword,
  handle: (r: HttpErrorResponse, injector: Injector) => {
    injector.get(ToastService).displayError(r);
    return throwError(() => r);
  },
  priority: Infinity, // Go before any other handler
};


@Injectable()
export class MetaEffects {
  public init$ = createEffect(() => this.actions$.pipe(
    ofType(metaActionInit),
    switchMap(({user}) => of(
      ...(user instanceof Patient || user instanceof Contact
        ? [
          this.actionFactory.create({entityName: Article.getEntityName(), entityOp: ForlabsEntityOp.QUERY_METADATA as unknown as EntityOp}),
          this.actionFactory.create({entityName: CourseMessage.getEntityName(), entityOp: EntityOp.QUERY_ALL}),
        ]
        : []
      ),
      this.actionFactory.create({entityName: Info.getEntityName(), entityOp: EntityOp.QUERY_ALL}),
      this.actionFactory.create({entityName: Organization.getEntityName(), entityOp: EntityOp.QUERY_ALL}),
      // ...(user instanceof HealthPro && user.role === 'ROLE_ADMIN'
      //   ? [this.actionFactory.create({entityName: Organization.getEntityName(), entityOp: EntityOp.QUERY_ALL})]
      //   : []
      // ),
      this.actionFactory.create({entityName: PatientStep.getEntityName(), entityOp: EntityOp.QUERY_ALL}),
      this.actionFactory.create({entityName: Contact.getEntityName(), entityOp: EntityOp.QUERY_ALL}),
      this.actionFactory.create({entityName: HealthPro.getEntityName(), entityOp: EntityOp.QUERY_ALL}),
      this.actionFactory.create({entityName: Patient.getEntityName(), entityOp: EntityOp.QUERY_ALL}),
      ...(user instanceof HealthPro && user.role === 'ROLE_ADMIN'
        ? [this.actionFactory.create({entityName: WorkflowButton.getEntityName(), entityOp: EntityOp.QUERY_ALL})]
        : []
      ),
      this.actionFactory.create({entityName: Workflow.getEntityName(), entityOp: EntityOp.QUERY_ALL}),
    )),
  ));

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActionTypes.REFRESH_FAILURE),
    tap((action) => console.info("lalala", action)),
    // Vous devez retourner un observable, même si c'est un 'of' vide si vous ne faites rien ensuite
    map(() => ({ type: '[Auth] Logout Success' })) // Exemple d'action à retourner après le logout
  ));

  public secureAction$ = createEffect(() => this.actions$.pipe(
    ofType(metaActionSecureAction),
    mergeMap(({action, password, specs}) => this.apiClient.post(
      '/api/request_sensitive_action_token',
      {
        password,
        specs,
      },
      {
        context: new HttpContext().set(errorHandlersHttpContextToken, [sensitiveActionIncorrectPasswordHandler]),
      } as HttpClientOptions & { context?: HttpContext }, // FIXME: no need to type this once we've updated ApiBridge
    ).pipe(
      tap((x) => console.log({action, x})),
      map(({token}) => ({
        ...action,
        payload: {
          ...action.payload, // Note that this also copies correlation ID
          httpOptions: {httpHeaders: {Authorization: `Bearer ${token}`}},
        },
      })),
      catchError(err => {
        // FIXME: maybe it's not the correct way to do it? I'd like a code review on this
        return of({
          ...action,
          type: FailedSecureAction.type,
          payload: {
            ...action.payload,
            entityOp: EntityOp.SAVE_ADD_ONE_ERROR,
          },
        });
      }),
    )),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly actionFactory: EntityActionFactory,
    private readonly apiClient: ApiClient,
  ) {
  }
}
