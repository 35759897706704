import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor() { }

  getBrowserLanguage(): string {
    const language = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
    return language;
  }
}

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  constructor(
    private languageService: LanguageService,
    private translocoService: TranslocoService,
) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const browserLanguage = this.languageService.getBrowserLanguage();

    // Cloner la requête et ajouter le nouvel en-tête
    const modifiedReq = req.clone({ 
      setHeaders: { 
        'Accept-Language': this.translocoService.getActiveLang(),
      }
    });

    return next.handle(modifiedReq);
  }
}