import {Injectable} from '@angular/core';
import {relatedEntitySelectorWithTransformer} from '@followme/ngrx-entity-relationship';
import {buildOne} from '@forlabs/api-bridge';
import {Store} from '@ngrx/store';
import {relatedEntitySelector} from 'ngrx-entity-relationship';
import {combineLatest, combineLatestWith, map, Observable, switchMap} from 'rxjs';
import {Info} from '../infos/infos.models';
import {InfoCollectionService} from '../infos/infos.ngrx-data';
import {EntitySelectorService} from '../metadata';
import {Organization} from '../organizations/organizations.models';
import {OrganizationCollectionService} from '../organizations/organizations.ngrx-data';
import {PatientStepInterface} from '../patient-steps/patient-steps.models';
import {PatientStepCollectionService} from '../patient-steps/patient-steps.ngrx-data';
import {PlaceStatuses, Workflow} from '../workflows/workflows.models';
import {WorkflowCollectionService} from '../workflows/workflows.ngrx-data';
import {WorkflowSelectorService} from '../workflows/workflows.selectors';
import {
  Contact,
  ContactInterface,
  ExitStatus,
  HealthPro,
  HealthProInterface,
  Patient,
  PatientInterface, PatientStatus
} from './users.models';
import {ContactCollectionService, HealthProCollectionService, PatientCollectionService} from './users.ngrx-data';


@Injectable()
export class ContactSelectorService extends EntitySelectorService<ContactInterface, Contact> {
  constructor(
    store: Store,
    contactCollectionService: ContactCollectionService,
    patientCollectionService: PatientCollectionService,
    organizationCollectionService: OrganizationCollectionService,
  ) {
    const relContactOrganization = relatedEntitySelectorWithTransformer(
      organizationCollectionService,
      'organizationIri' satisfies (keyof ContactInterface),
      'organization' satisfies (keyof ContactInterface),
      organization => buildOne(Organization, organization),
    );

    const relContactPatients = relatedEntitySelectorWithTransformer(
      patientCollectionService,
      'patientsIris' satisfies (keyof ContactInterface),
      'patients' satisfies (keyof ContactInterface),
      patient => buildOne(Patient, patient),
    );

    super(
      store,
      Contact,
      contactCollectionService,
      [
        relContactOrganization(),
        relContactPatients(),
      ],
    );
  }
}


@Injectable()
export class HealthProSelectorService extends EntitySelectorService<HealthProInterface, HealthPro> {
  constructor(
    store: Store,
    healthProCollectionService: HealthProCollectionService,
    organizationCollectionService: OrganizationCollectionService,
  ) {
    const relHealthProOrganization = relatedEntitySelectorWithTransformer(
      organizationCollectionService,
      'organizationIri' satisfies (keyof HealthProInterface),
      'organization' satisfies (keyof HealthProInterface),
      organization => buildOne(Organization, organization),
    );

    super(
      store,
      HealthPro,
      healthProCollectionService,
      [
        relHealthProOrganization(),
      ],
    );
  }
}

@Injectable()
export class PatientSelectorService extends EntitySelectorService<PatientInterface, Patient> {
  constructor(
    private readonly workflowSelectorService: WorkflowSelectorService,
    store: Store,
    patientCollectionService: PatientCollectionService,
    organizationCollectionService: OrganizationCollectionService,
    contactCollectionService: ContactCollectionService,
    infoCollectionService: InfoCollectionService,
    patientStepCollectionService: PatientStepCollectionService,
    workflowCollectionService: WorkflowCollectionService,
  ) {
    const relPatientOrganization = relatedEntitySelectorWithTransformer(
      organizationCollectionService,
      'organizationIri' satisfies (keyof PatientInterface),
      'organization' satisfies (keyof PatientInterface),
      organization => buildOne(Organization, organization),
    );

    const relPatientInfo = relatedEntitySelectorWithTransformer(
      infoCollectionService,
      'lastInfoIri' satisfies (keyof PatientInterface),
      'lastInfo' satisfies (keyof PatientInterface),
      info => buildOne(Info, info),
    );

    const relPatientContacts = relatedEntitySelectorWithTransformer(
      contactCollectionService,
      'contactsIris' satisfies (keyof PatientInterface),
      'contacts' satisfies (keyof PatientInterface),
      contact => buildOne(Contact, contact),
    );

    // FIXME: Once we're done fixing the lib, use relatedEntitySelectorWithTransformer instead and update Patient.fromJson
    // const relPatientPatientSteps = relatedEntitySelectorWithTransformer(
    const relPatientPatientSteps = relatedEntitySelector(
      patientStepCollectionService,
      'stepsIris' satisfies (keyof PatientInterface),
      'steps' satisfies (keyof PatientInterface),
      // patientStep => buildOne(PatientStep, patientStep),
    );

    const relPatientStepWorkflow = relatedEntitySelectorWithTransformer(
      workflowCollectionService,
      'step' satisfies (keyof PatientStepInterface),
      'workflow' satisfies (keyof PatientStepInterface),
      workflow => buildOne<Workflow>(Workflow, workflow),
    );

    super(
      store,
      Patient,
      patientCollectionService,
      [
        relPatientOrganization(),
        relPatientInfo(),
        relPatientContacts(),
        relPatientPatientSteps(
          relPatientStepWorkflow(),
        ),
      ],
    );
  }

  public implicitSteps$(patientIri: string): Observable<PlaceStatuses> {
    return this.oneByIri$(patientIri).pipe(
      combineLatestWith(
        // Workflows are not stored by iri, but directly by name, even though the method is still called "oneByIri$"
        this.workflowSelectorService.oneByIri$('patient'),
        this.workflowSelectorService.oneByIri$('exit'),
      ),
      map(([patient, patientWorkflow, exitWorkflow]) =>
        ({
          ...(patientWorkflow?.metadata?.place?.[patient.orientationStatus]?.implicit_steps ?? {}),
          ...(exitWorkflow?.metadata?.place?.[patient.exitStatus]?.implicit_steps ?? {}),
        })),
    );
  }
}
