import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {fetchDynamicEnvironment} from '@forlabs/api-bridge';
import {initSentry} from '../../../libs/sentry-integration/sentry-integration';
import {AppModule} from './app/app.module';


async function main() {
  try {
    const env = await fetchDynamicEnvironment();

    if (env['sentryDsn']) {
      // Sentry.init() should be called as early as possible in your application's lifecycle
      initSentry(env['sentryDsn']);
    }

    await platformBrowserDynamic().bootstrapModule(AppModule);
  } catch (error) {
    console.error('Bootstrap error', error);
  }
}

main().then();
