import {HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptors} from '@angular/common/http';
import {isDevMode, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouteReuseStrategy} from '@angular/router';
import {ServiceWorkerModule} from '@angular/service-worker';
import {DomainModule, LanguageInterceptor, LanguageService, TranslocoRootModule} from '@followme/domain';
import {SUCCESS_MESSAGES} from '@followme/shared/ui-core';
import {ReInitRouteReuseStrategy} from '@followme/shared/util-core';
import {
  dynamicEnvironment,
  ENVIRONMENT,
  ErrorEventsService,
  HeadersInterceptor,
  HeadersService,
  HttpErrorInterceptor,
  TOAST_SUCCESS_MESSAGES,
} from '@forlabs/api-bridge';
import {IonicModule} from '@ionic/angular';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {getSentryAngularProviders} from '../../../../libs/sentry-integration/sentry-integration';
import {staticEnvironment} from '../environments/static-environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';


// EntityBuilder.enableCamelConversion = false;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    DomainModule,
    BrowserAnimationsModule,
    !staticEnvironment.production ? StoreDevtoolsModule.instrument() : [],
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    TranslocoRootModule,
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: ReInitRouteReuseStrategy},
    {provide: ErrorEventsService, useClass: ErrorEventsService},
    {provide: HeadersService, useClass: HeadersService},
    {provide: ENVIRONMENT, useValue: dynamicEnvironment},
    {provide: TOAST_SUCCESS_MESSAGES, useValue: SUCCESS_MESSAGES},
    {provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    ...getSentryAngularProviders(),
    LanguageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
